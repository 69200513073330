import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

const AppReuseCollapsibleWapper = styled.div`
  button {
    text-transform: capitalize;
  }
  button:focus {
    outline: none;
  }
`

export const AppReuseCollapsible = (props: any) => {
  const [isOpen, setIsOpen] = useState(false)

  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <AppReuseCollapsibleWapper>
      <motion.div
        className={props.AppReuseCollapsible}
        ref={ref}
        animate={controls}
        initial='hidden'
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 25 },
        }}
        transition={{ ease: 'easeOut', duration: 1.25, delay: 0.35 }}>
        <div className={props.ButtonCenter}>
          <button onClick={() => setIsOpen(!isOpen)} className={props.CollapsibleButton}>
            {props.ButtonName}
            <span className={props.IncoColor}>{`${isOpen ? '▲' : '▼'}`}</span>
          </button>
          {isOpen && <div className={props.content}>{props.children}</div>}
        </div>
      </motion.div>
    </AppReuseCollapsibleWapper>
  )
}
