import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { AppReuseCollapsible } from './AppReuseCollapsible'
import SourcingEventsCollapsibleData from '../Data/SourcingEventsCollapsibleData.json'

const AppSourcingEventsCollapsibleWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .SourcingEventsHeadingCollapsible {
    text-align: center;
    padding: 50px 0px 10px 0px;
  }
  .SourcingEventsKeyPara {
    text-align: left;
    max-width: 1000px;
    margin: auto;
    display: flex;
    padding-bottom: 50px;
    @media (max-width: 1090px) {
      padding: 0px 15px 50px 15px;
    }
  }
  .CollapsibleSection {
    padding: 0px 0px 50px 0px;
    max-width: 13900px;
    margin: auto;
    @media (max-width: 1090px) {
      padding: 0px 15px 2rem 15px;
    }
  }
  .CollapsibleView {
    display: flex;
    max-width: 1390px;
    justify-content: space-evenly;
    margin: auto;
    padding: 0px 15px 2rem 15px;
    @media (max-width: 7680px) {
      max-width: 1590px;
      flex-wrap: wrap;
    }
  }
  .CollapsibleDisplaySection {
    width: 650px;
    padding-bottom: 50px;
  }
  .SourcingEventsCollapsiblePara {
    text-align: left;
    max-width: 650px;
    margin: auto;
    padding: 10px;
    font-size: 1rem;
  }
  .IncoColor {
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    padding-left: 100px;
    padding-right: 10px;
    @media (max-width: 700px) {
      display: none;
    }
  }
  .CollapsibleButton {
    height: 50px;
    width: 650px;
    border: none;
    color: #fff;
    background-color: #34a1d5;
    font-size: 20px;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    @media (max-width: 700px) {
      height: auto;
      width: 350px;
      text-align: left;
    }
    @media (max-width: 375px) {
      height: auto;
      width: 320px;
      text-align: left;
    }
  }
  .content {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom: 2px solid #34a1d5;
    border-left: 2px solid #34a1d5;
    border-right: 2px solid #34a1d5;
  }
`

export const AppSourcingEventsCollapsible = () => {
  return (
    <AppSourcingEventsCollapsibleWapper>
      <div className="HeadingSection">
        <ReUseHTwotag Heading="Raindrop Sourcing Features" HeadingStyle="SourcingEventsHeadingCollapsible" />
        <ReUsePtag
          para="Raindrop’s Sourcing module is a highly intuitive and easy to use solution, designed to take your business out to market quickly and efficiently.  Gain the desired results in a compressed amount of time with Raindrop’s streamlined and digitized process. Raindrop helps to take your request out to a global or domestic supplier base in just a few clicks. Define your scope, invite vetted bidders, and the intuitive platform will run and manage the entire bidding process, resulting in competitive bids and analysis from your qualified suppliers for the desired goods and services."
          paraStyle="SourcingEventsKeyPara"
        />
        <div className="CollapsibleSection">
          <div className="CollapsibleView">
            {SourcingEventsCollapsibleData.map((data, index) => {
              return (
                <div className="CollapsibleDisplaySection" key={index}>
                  <AppReuseCollapsible
                    ButtonName={data.title}
                    ButtonCenter=""
                    AppReuseCollapsible="AppReuseCollapsible"
                    CollapsibleButton="CollapsibleButton"
                    IncoColor="IncoColor"
                    content="content"
                  >
                    <ReUsePtag para={data.Para} paraStyle="SourcingEventsCollapsiblePara" />
                  </AppReuseCollapsible>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </AppSourcingEventsCollapsibleWapper>
  )
}
